import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export const useWindowPosition = (debounceTime = 25) => {
    const [scrollPosition, setPosition] = useState(0);
    useEffect(() => {
        const updatePosition = () => {
            setPosition(window.scrollY);
        };
        const throttledUpdate = throttle(updatePosition, debounceTime, {
            leading: true,
            trailing: true,
        });
        window.addEventListener('scroll', throttledUpdate);
        updatePosition();
        return () => window.removeEventListener('scroll', throttledUpdate);
    }, []);
    return scrollPosition;
};
