import { useFrame } from '$shared/utils';
import { useEffect, useMemo, useState } from 'react';
import { N35MobileMegaMenu } from '~/features/navigation';
import { N30MegaMenu } from '~/features/navigation/components/N30MegaMenu';
import { Logo } from '~/shared/components/Logo';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { MobileMegaMenuButton } from './components/MobileMegaMenuButton';

import Globe from '$icons/globe.svg';
import Loop from '$icons/search.svg';
import { useTheme } from '@emotion/react';
import { Icon, StyledMaxWidth, useKompanGetQuotePanelState } from '~/shared/components';
import { StyledGutter } from '~/shared/components/Gutter/styled';
import { useMarketSelectorState } from '~/shared/components/MarketSelector';
import { useNavigatingState } from '~/shared/components/NavigatingSpinner/Hooks';
import { useScrollLock } from '~/shared/hooks';
import { useScrollDirection } from '~/shared/hooks/useScrollDirection';
import { useTranslation } from '~/shared/utils/translation';
import { N25SearchBar, useSearchState } from '../N25SearchBar';
import LoginButton from './components/LoginButton';
import {
    StyledBackdrop,
    StyledDesktopButtons,
    StyledDesktopNav,
    StyledLanguageButton,
    StyledButtonMobile,
    StyledLogoContainer,
    StyledMainNavigation,
    StyledMainNavigationContent,
    StyledMobileMegaMenuWrapper,
    StyledNavigationActionButton,
    StyledNavigationActionButtonDesktopWrapper,
    StyledNavigationButtonWrapper,
    StyledSearchButton,
    StyledSearchButtonMobile,
} from './styled';
import { FavoritesButton } from './components';
import { usePage } from '~/templates/pages';
import { useWindowPosition } from '~/shared/hooks/useWindowPosition/useWindowPosition';

export type Props = {
    isFloating: boolean;
    showSearch?: boolean;
    showLoginButton?: boolean;
};

const N20MainMenu = ({ isFloating, showSearch = true, showLoginButton = false }: Props) => {
    const scrollDirection = useScrollDirection();
    const { data } = useFrame();
    const { type: pageType } = usePage();

    const { colors } = useTheme();
    const [megaMenuActive, setMegaMenuActive] = useState(false);
    const navigation = data?.navigation || {};
    const { isOpen: isSearchOpen, setIsOpen } = useSearchState();
    const { translate } = useTranslation();
    const { show: showMarketSelector } = useMarketSelectorState();
    const { show } = useKompanGetQuotePanelState();
    const windowScroll = useWindowPosition();
    const scrollSafeZone = 100; // safe distance before menu start scrolling
    const { navigating } = useNavigatingState();
    const { isLocked, scrollY } = useScrollLock();

    useEffect(() => {
        if (navigating) {
            setMegaMenuActive(false);
        }
    }, [navigating]);

    useEffect(() => {
        if (isSearchOpen) {
            setMegaMenuActive(false);
        }
    }, [isSearchOpen]);

    const isMenuTransparent = useMemo(() => {
        return isFloating && !isSearchOpen && windowScroll <= scrollSafeZone && !megaMenuActive;
    }, [isFloating, isSearchOpen, windowScroll, megaMenuActive]);

    const getSearchButton = useMemo(
        () => (
            <StyledNavigationButtonWrapper>
                <StyledSearchButton
                    variant={'Plain'}
                    onClick={() => setIsOpen(true)}
                    aria-label={translate('Kompan.Search.Search')}
                    color={isMenuTransparent ? colors.white : colors.black}
                >
                    <Icon size="md">
                        <Loop />
                    </Icon>
                </StyledSearchButton>
            </StyledNavigationButtonWrapper>
        ),
        [isMenuTransparent],
    );

    const getFavoriteButton = useMemo(
        () => <FavoritesButton isMenuTransparent={isMenuTransparent} />,
        [isMenuTransparent],
    );

    const getLogoutButton = useMemo(() => {
        return (
            <StyledNavigationActionButtonDesktopWrapper>
                <LoginButton variant={'Red'} shade={'dark'} />
            </StyledNavigationActionButtonDesktopWrapper>
        );
    }, []);

    const getLanguageButton = useMemo(
        () => (
            <StyledNavigationButtonWrapper>
                <StyledLanguageButton
                    variant="Plain"
                    onClick={() => showMarketSelector()}
                    aria-label={translate('Kompan.MarketSelector.ChangeButtonText')}
                    color={isMenuTransparent ? colors.white : colors.black}
                >
                    <Icon size="md">
                        <Globe />
                    </Icon>
                </StyledLanguageButton>
            </StyledNavigationButtonWrapper>
        ),
        [isMenuTransparent],
    );

    const shouldHideMenu = useMemo(() => {
        // PDP has it's own special submenu that should be shown instead.
        if (pageType === 'p70ProductDetailsPageTemplate') {
            return true;
        }
        // Regular scroll logic, hide when scrolling down, show when scrolling up.
        const shouldHideFromScroll =
            scrollDirection === 'down' && !isSearchOpen && windowScroll > scrollSafeZone;

        // Special case for when sidepanels are open.
        const shouldHideFromSidepanel =
            isLocked && scrollDirection == 'down' && scrollY > scrollSafeZone;

        return shouldHideFromScroll || shouldHideFromSidepanel;
    }, [scrollDirection, isSearchOpen, windowScroll, scrollSafeZone, isLocked, scrollY, pageType]);

    const onActiveMenuChange = (active: boolean) => setMegaMenuActive(active);

    return (
        <StyledMainNavigation
            isFloating={isFloating}
            hide={shouldHideMenu}
            isTransparent={isMenuTransparent}
        >
            {isFloating && <StyledBackdrop active={isMenuTransparent} />}
            <StyledMaxWidth>
                <StyledGutter>
                    <StyledMainNavigationContent>
                        <StyledMobileMegaMenuWrapper>
                            <MobileMegaMenuButton isWhite={isMenuTransparent} />
                            {showSearch && (
                                <StyledSearchButtonMobile>
                                    {getSearchButton}
                                </StyledSearchButtonMobile>
                            )}
                        </StyledMobileMegaMenuWrapper>
                        <StyledLogoContainer>
                            <Logo
                                description="Logo - Frontpage"
                                color={isMenuTransparent ? colors.white : colors.red}
                            />
                        </StyledLogoContainer>
                        <StyledMobileMegaMenuWrapper>
                            <StyledButtonMobile>{getLanguageButton}</StyledButtonMobile>
                            <StyledButtonMobile>{getFavoriteButton}</StyledButtonMobile>
                        </StyledMobileMegaMenuWrapper>
                        <StyledDesktopNav>
                            <N30MegaMenu
                                navigation={navigation}
                                onActiveChange={onActiveMenuChange}
                                linkTextColor={isMenuTransparent ? colors.white : colors.black}
                            />
                        </StyledDesktopNav>
                        <StyledDesktopButtons>
                            {showSearch && getSearchButton}
                            {getLanguageButton}
                            {getFavoriteButton}
                            {navigation.getAQuote?.getAQuoteEnabled &&
                                navigation.getAQuote?.getAQuoteModule && (
                                    <StyledNavigationActionButtonDesktopWrapper>
                                        <StyledNavigationActionButton
                                            variant={'Red'}
                                            shade={'dark'}
                                            onClick={show}
                                        >
                                            {translate('Kompan.MainMenu.GetQuoteButtonLabel')}
                                        </StyledNavigationActionButton>
                                    </StyledNavigationActionButtonDesktopWrapper>
                                )}
                            {showLoginButton && getLogoutButton}
                        </StyledDesktopButtons>
                        <N25SearchBar />
                    </StyledMainNavigationContent>
                </StyledGutter>
            </StyledMaxWidth>
            <N35MobileMegaMenu activeNodeByUrl={true} />
        </StyledMainNavigation>
    );
};

const N20MainMenuWithErrorBoundary = withErrorBoundary(N20MainMenu);
N20MainMenuWithErrorBoundary.displayName = 'N20MainMenu';
export default N20MainMenuWithErrorBoundary;
