import { Button, StyledLink } from '$shared/components';
import styled from '@emotion/styled';
import { readableColor, transparentize } from 'color2k';
import { breakpoints } from '~/theme';
import { ifProp } from 'styled-tools';

export interface StyledMainNavigationProps {
    isFloating: boolean;
    isTransparent: boolean;
    hide: boolean;
}

export const StyledMenuLink = styled(StyledLink)(({ theme }) => ({
    color: theme.colors.black,
    fontSize: theme.fontSizes['sm'],
    padding: `${theme.spaces[5]} ${theme.spaces[1]}`,
}));

export const StyledIconBadge = styled.span<{ backgroundColor?: string }>(
    ({ theme, backgroundColor = theme.colors.grey40 }) => ({
        borderRadius: '50%',
        position: 'absolute',
        top: '-3px',
        left: '-3px',
        fontWeight: 'bold',
        fontSize: '0.9em',
        lineHeight: '1.4em',
        width: '1.4em',
        height: '1.4em',
        backgroundColor: backgroundColor,
        color: readableColor(backgroundColor),
        padding: 2,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    }),
);

export const StyledMainNavigation = styled.header<StyledMainNavigationProps>(
    ({ theme, isFloating }) => ({
        padding: `${theme.spaces['3']} 0`,
        top: 0,
        position: isFloating ? 'fixed' : 'sticky',
        backgroundColor: transparentize(theme.colors.white100, 0.02),
        zIndex: theme.zIndexes.navigation,
        width: '100%',
        transitionDuration: '350ms',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionProperty: 'top, background-color',
        willChange: 'top, background-color',
        height: theme.navigationHeight.mobile,
        [breakpoints.sm]: {
            padding: 0,
            height: theme.navigationHeight.desktop,
        },
    }),
    ifProp('isTransparent', {
        backgroundColor: 'transparent',
    }),
    ifProp('hide', ({ theme }) => ({
        top: `-${theme.navigationHeight.mobile}px`,
        [breakpoints.sm]: {
            top: `-${theme.navigationHeight.desktop}px`,
        },
    })),
);

export const StyledBackdrop = styled.div<{ active: boolean }>(
    {
        display: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 70%) 0%, rgba(0, 0, 0, 0) 100%)',
        transition: 'opacity 0.3s ease',
        opacity: 0,
        zIndex: -1,
        [breakpoints.sm]: {
            display: 'block',
        },
    },
    ifProp('active', {
        opacity: 1,
    }),
);

export const StyledContent = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    justifyContent: 'flex-end',
}));

export const StyledNav = styled.nav({
    display: 'grid',
    gridAutoFlow: 'column',
});

export const StyledDesktopNav = styled.div(({ theme }) => ({
    display: 'none',
    [breakpoints.sm]: {
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'baseline',
        gap: 25,
        marginLeft: theme.direction === 'rtl' ? 0 : 'auto',
        marginRight: theme.direction === 'rtl' ? 'auto' : 0,
    },
}));

export const StyledLogoContainer = styled.div(({ theme }) => ({
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    marginRight: 0,
    [breakpoints.sm]: {
        position: 'unset',
        transform: 'translateX(0)',
    },
    '.no-js & svg': {
        fill: theme.colors.red,
    },
}));

export const StyledNavigationButtonWrapper = styled.div();

export const StyledSearchButton = styled(Button)<{ color: string }>(({ theme, color }) => ({
    margin: 0,
    paddingLeft: theme.spaces[3],
    paddingRight: theme.spaces[3],
    color: color,

    'figure, svg': {
        marginTop: 0,
        color: color,
        transition: `color ${theme.animations.timingShort} ${theme.animations.easeOutCubic}`,
        fill: 'currentColor',
    },
}));

export const StyledMobileMenuButtonWrapper = styled.div(({ theme }) => ({
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spaces[2],
    [breakpoints.sm]: {
        display: 'none',
    },
}));

export const StyledMobileMenuButton = styled(Button)(({ theme }) => ({
    fontSize: theme.fontSizes['3xl'],
    padding: theme.spaces[2],
    width: 'auto',

    'figure, svg, :hover figure, :hover svg': {
        marginTop: 0,
        fill: 'currentColor',
        color: 'inherit',
    },
}));

export const StyledMainNavigationContent = styled(StyledContent)(({ theme }) => ({
    justifyContent: 'space-between',
    marginLeft: `-${theme.spaces[2]}`,
    marginRight: `-${theme.spaces[2]}`,
    [breakpoints.sm]: {
        marginLeft: 0,
        marginRight: 0,
        justifyContent: 'flex-end',
        gap: theme.spaces[4],
    },
}));

export const StyledSearchButtonMobile = styled.div({
    display: 'block',
    [breakpoints.sm]: {
        display: 'none',
    },
});

export const StyledDesktopButtons = styled.div(() => ({
    display: 'none',
    [breakpoints.sm]: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const StyledMobileMegaMenuWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    ['button']: {
        padding: theme.spaces[2],
        margin: 0,
    },
}));

export const StyledLanguageButton = styled(Button)<{ color: string }>(({ theme, color }) => ({
    margin: 0,
    paddingLeft: theme.spaces[3],
    paddingRight: theme.spaces[3],
    color: color,

    'figure, svg': {
        marginTop: 0,
        color: color,
        transition: `color ${theme.animations.timingShort} ${theme.animations.easeOutCubic}`,
        fill: 'currentColor',
    },
}));

export const StyledButtonMobile = styled.div({
    display: 'block',
    [breakpoints.sm]: {
        display: 'none',
    },
});

export const StyledNavigationActionButtonDesktopWrapper = styled.div(({ theme }) => ({
    marginLeft: theme.direction !== 'rtl' ? theme.spaces['3'] : 0,
    marginRight: theme.direction === 'rtl' ? theme.spaces['3'] : 0,
}));

export const StyledButtonMobileWrapper = styled.div(({ theme }) => ({
    paddingLeft: `${theme.spaces[7]}`,
    paddingRight: `${theme.spaces[7]}`,
    '+ &': {
        marginTop: theme.spaces[4],
    },
}));

export const StyledNavigationActionButton = styled(Button)(({ theme }) => ({
    padding: `${theme.spaces['3']} ${theme.spaces['4']}`,
    fontSize: theme.fontSizes.xs,
}));
